<template>
    <b-sidebar
      :visible="isAddNewPackageSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="$emit('clicked',false)"
      @change="(val) => $emit('one')"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Settle Overdue
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
        <div class="p-2">
          <label class="section-label mb-1">Payment Details</label>
          <validation-provider
              #default="validationContext"
              name="Payment Method"
              rules="required"
            >
              <b-form-group
                label="Payment Method"
                label-for="payment-method"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="packageData.payment_method"
                  :reduce="val => val.value"
                  :options="paymentMethods"
                  :clearable="false"
                  input-id="paymentMethod"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <validation-provider
              #default="validationContext"
              rules="required|between:0,100000"
              name="Payment Amount"
            >
              <b-form-group
                label="Payment Amount"
                label-for="payment_amount"
              >
                <b-form-input
                  id="payment_amount"
                  v-model="packageData.payment_amount"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div v-for="(payment, index) in payment_method">
              {{payment.method}} - $ {{payment.amount}}
            </div>
            <h4>Overdue Amount : ${{total}}</h4>
            <div class="mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="button"
                block
                @click="add_payment()"
              >
                Add Payment
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="outline-warning"
                block
                @click="clear_payment()"
              >
                Clear Payment
              </b-button>
  
            <!--  <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="$router.push(`/epad/6/`)"
              >
                GO
              </b-button> -->
  
            </div>
            <hr>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              class="mt-2"
              type="button"
              @click="submit_now()"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              block
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BModal, VBModal, BFormTextarea
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import flatPickr from 'vue-flatpickr-component'
  import { ref } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import countries from '@/@fake-db/data/other/countries'
  import router from '@/router'
  
  export default {
    components: {
      ToastificationContent,
      flatPickr,
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BModal, VBModal,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
      'b-modal': VBModal,
    },
    model: {
        prop: 'isAddNewMemberSidebarActive',
        event: 'update:is-add-new-Member-sidebar-active',
    },
    props: {
        type: {
            required:true,
            type:String
        },
      overdue: {
        required: true
      },
      paymentMethods: {
        type: Array,
        required: false
      },
      isAddNewPackageSidebarActive: {
        required: true,
      },
    },
    computed: {
      total() {
        let total = 0
        this.payment_method.forEach((item, i) => {
          total = total + parseInt(item.amount)
        });
   
        return this.overdue - total
      },
      default_expiry_date() {
        try {
        var myCurrentDate=new Date();
        var myFutureDate=new Date(myCurrentDate);
        var expiry_figure = Number(this.packageData.package.expiry_figure)
        switch(this.packageData.package.expiry_unit) {
          case "DAY":
            myFutureDate.setDate(myFutureDate.getDate()+ expiry_figure)
            break;
          case "MONTH":
            myFutureDate.setMonth(myFutureDate.getMonth()+ expiry_figure)
            break;
          case "YEAR":
            myFutureDate.setYear(myFutureDate.getFullYear()+ expiry_figure)
            break;
        }
        } catch {
          var myCurrentDate=new Date();
          var myFutureDate=new Date(myCurrentDate);
        }
        return myFutureDate.getFullYear() + "-" +  (myFutureDate.getMonth()+1)  + "-" + myFutureDate.getDate()
      },
      selected_package_price() {
        try {
        return this.packageData.package.price
        } catch {
          return 0
        }
      },
      selected_package_quantity() {
        try {
        switch (Number(this.packageData.package.lock_quantity)) {
          case 0:
            this.lock_quantity = false
            break;
          case 1:
            this.lock_quantity = true
            break;
        }
         return this.packageData.package.quantity
        } catch {
        return 0
        }
  
      }
    },
    data() {
      return {
        payment_method : [],
        iframe_src : '//www.cloudfit.pro/invoice.php',
        required,
        paymentMethodOptions: [
          { label: 'Cash', value: 'cash' },
          { label: 'Visa', value: 'visa' },
        ],
        lock_quantity : true,
        alphaNum,
        email,
        countries,
        blankPackageData: {
          expire_at: '',
        },
        packageData:{
          center_id:0,
          remarks:null,
          package:{
            price:0.00,
            quantity:0,
            payment_method:'',
            expiry_figure:'1',
            expiry_unit:'DAY'
          }
        }
      }
    },
    created() {
    },
    methods: {
      async print () {
        // Pass the element id here
        await this.$htmlToPaper('printMe');
      },
      add_payment() {
        this.payment_method.push({
          "method" : this.packageData.payment_method,
          "amount" : this.packageData.payment_amount
        })
        this.packageData.payment_amount = 0
      },
      clear_payment() {
        this.payment_method = [];
        this.packageData.payment_amount = 0
      },
      submit_now() {
        console.log(this.payment_method)
        if ( this.total > 0 ) { 
            
            this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: "尚欠餘款",
                 },
               })
        return  }
        
      this.payment_method.forEach((item, i) => {
        if (item.method == "STRIPE") {
          console.log("ERROR METHOD")
          return
        }

      });
      var api = null
      switch(this.type) {
        case "package":
         api = process.env.VUE_APP_API_BASE+`/package/`+ router.currentRoute.params.id + `/manual_overdue/`
         break
        case "membership":
         api = process.env.VUE_APP_API_BASE+`/membership/`+ router.currentRoute.params.id + `/manual_overdue/`
      }
      this.$http.post(api,{ "payments" : this.payment_method })
        .then(res => {
          if (res.data.result === true) {
                this.$emit('clicked', false)
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
      },
    },
    setup(props, { emit }) {
      const blankPackageData = {
        expire_at:'',
        remakrs:'',
        quantity:'',
        package: {
          expiry_figure:'1',
          expiry_unit:'DAY'
        }
      }
  
      const packageData = ref(JSON.parse(JSON.stringify(blankPackageData)))
      const resetuserData = () => {
        packageData.value = JSON.parse(JSON.stringify(blankPackageData))
      }
  
      const onSubmit = () => {
  
      }
  
      const {
        refFormObserver,
        getValidationState,
      } = formValidation(resetuserData)
  
      return {
        packageData,
        onSubmit,
  
        refFormObserver,
        getValidationState,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  